import './login.scoped.scss';
import React from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ReactComponent as LoginLogo } from '@/assets/login-logo.svg';
import { ReactComponent as CreditLogo } from '@/assets/credit-logo.svg';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userAtom } from '@/atoms/general';
import { isMobileAtom } from '@/atoms/general';
import { useAtom } from 'jotai';
import axios from '@/configs/axios.js';

import { finishedLoginAtom } from '@/api/users';
import { getDocumentsAndFoldersAtom } from '@/api/documents';

const auth = getAuth();

export const Login = () => {
  const [user, setUser] = useAtom(userAtom);
  const [finishedLogin, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLoginForm((prev) => ({ ...prev, [name]: value }));
  };
  // const { oktaAuth, authState } = useOktaAuth()
  const navigate = useHistory();
  const handleLogin = async (e) => {
    e.preventDefault();
    let fireBaseResponse;
    let emailCheckResponse;
    /// add client side validation
    try {
      fireBaseResponse = await signInWithEmailAndPassword(auth, loginForm.email, loginForm.password);
      const fbUser = fireBaseResponse.user;
      emailCheckResponse = await axios.put('/api/user/login', {
        email: fbUser.email,
      });
      const { user } = emailCheckResponse.data;
      setUser({
        id: user.id,
        email: fbUser.email,
        userName: user.name,
        lang: 'english',
      });
      sessionStorage.setItem('lastAuthTimestamp', new Date().getTime());
      setFinishedLogin(true);
    } catch (err) {
      alert('username or password are invalid');
      console.log(err.message);
    }
  };
  if (finishedLogin) {
    navigate.push('/playground');
    getDocumentsAndFolders();
    // return <Redirect push to='/playground' />
  }

  return (
    <div className="login-page">
      <div className="modal-full-screen">
        <div
          className="modal-container"
          style={isMobile ? { width: '90%', overflowY: 'visible' } : { width: '55%', overflowY: 'auto' }}
        >
          <div className="modal-upper">
            <LoginLogo style={isMobile ? { width: '180px' } : { width: '253px' }} />
          </div>
          <div style={isMobile?{width:'300px'}:{width:'auto'}}>
            <h2>Jeen.AI Your AI environment</h2>
          </div>

          <div className="form-container">
            <form onSubmit={handleLogin}>
              <input
                value={loginForm.email}
                style={isMobile ? { width: '230px' } : { width: '490px' }}
                name="email"
                onChange={handleChange}
                type="text"
                placeholder="email"
              />
              <input
                value={loginForm.password}
                style={isMobile ? { width: '230px' } : { width: '490px' }}
                name="password"
                onChange={handleChange}
                type="password"
                placeholder="password"
              />
              <button
                onClick={handleLogin}
                className="login-btn"
                disabled={loginForm.email === '' || loginForm.password === ''}
              >
                Login
              </button>
            </form>
          </div>
        </div>
        <div className="bottom-credit" style={isMobile ? { left: '30%' } : { left: '45%' }}>
          <h4>Created By</h4>
          <CreditLogo />
        </div>
      </div>
    </div>
  );
};
