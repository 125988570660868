import { useEffect, useState } from 'react';
import SingleBotItem from '@/components/BotsListPage/SingleBotItem';
import './botList.scoped.css';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import ArrowDown from '@/assets/select-arraow-down.png';
import { userAtom, propertiesAtom } from '@/atoms/general';
import axios from '@/configs/axios.js';
import { useAtom } from 'jotai';
import LowerNavbar from '@/components/BotsListPage/LowerNavbar/LowerNavbar';
import BotCreator from '../BotCreator/BotCreator';
import { markedBotsAtom, updateMarkedBotsAtom, searchBotAtom } from '@/atoms/bots';
import { getTranslates, createTranslates } from '@/api/translates';
import { getBotData, createOrUpdateBot, getUserBots } from '@/api/bots';
import { getDefaultPromptsData } from '@/api/prompts';

let analysisPrompt, gradePrompt, relevantPrompt, hackPrompt;

const BotsList = ({ isOpenBotCreator, setIsOpenBotCreator }) => {
  const [searchQuery, setSearchQuery] = useAtom(searchBotAtom);
  const [properties] = useAtom(propertiesAtom);
  const [, setMarkedBots] = useAtom(updateMarkedBotsAtom);
  const [markedBots] = useAtom(markedBotsAtom);
  const [user] = useAtom(userAtom);
  const [allBotsData, setAllBotsData] = useState(null);
  const [botsDetails, setBotsDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isAllMarked, setIsAllMarked] = useState(false);
  const [characterPrompt, setCharacterPrompt] = useState('');
  const options = ['Date', 'Name A-Z', 'Name Z-A'];
  const [botUrl, setBotUrl] = useState('');

  console.log('marked-bots:', markedBots);
  useEffect(() => {
    getAllBotsUser();
    setBotUrl(properties.urls?.assistant_url);
  }, []);

  const getAllBotsUser = async () => {
    const userId = user.id;
    const data = await getUserBots(userId);
    setAllBotsData([...data]);
    setBotsDetails([...data]);
  };

  useEffect(() => {
    if (allBotsData) {
      const filteredAndSortedData = allBotsData
        .filter((item) => {
          // Implement your search filtering logic here
          // For example, if your items are objects with a 'name' property:
          if (item.bot_name) return item.bot_name.toLowerCase().includes(searchQuery.toLowerCase());
        })
        .sort((a, b) => {
          // Implement your sorting logic here based on the sortOption
          // For example:
          if (selectedOption === options[0]) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          } else if (selectedOption === options[1]) {
            return a.bot_name.localeCompare(b.bot_name);
          } else if (selectedOption === options[2]) {
            return b.bot_name.localeCompare(a.bot_name);
          }
          // Add more sort options as needed
          return 0;
        });
      setBotsDetails([...filteredAndSortedData]);
    }
  }, [selectedOption, searchQuery]);

  // Filter and sort the data based on the search query and sort option

  const toggleDropdown = () => setIsOpen(!isOpen);

  const onClickRow = (botDetails) => {
    setMarkedBots({ type: 'markBot', payload: { val: botDetails } });
  };

  const handleMarkAllBots = () => {
    setMarkedBots({ type: 'markAllBots', payload: { val: isAllMarked ? [] : botsDetails } });
    setIsAllMarked(!isAllMarked);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const openBotCreator = () => {
    setIsOpenBotCreator(true);
  };

  const handleSearchChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  const getCharacterPrompt = async (bot_description, bot_audience) => {
    const characterPrompt = await axios.put(`/api/bots/character-prompt`, {
      bot_description,
      bot_audience,
    });
    setCharacterPrompt(characterPrompt.data.characterPrompt);
    // analysisPrompt = characterPrompt.data.analysisPrompt;
    // gradePrompt = characterPrompt.data.gradePrompt;
  };

  const getBotDefaultPrompts = async () => {
    const defaultPrompts = await getDefaultPromptsData();

    analysisPrompt = defaultPrompts.analysis_prompt;
    gradePrompt = defaultPrompts.grade_prompt;
    hackPrompt = defaultPrompts.hack_prompt;
    relevantPrompt = defaultPrompts.relevant_answer_prompt;
    setCharacterPrompt(defaultPrompts.character_prompt.content);
  };

  const duplicateBots = async (chosenBots) => {
    chosenBots.forEach(async (chosenBot) => {
      const data = await getBotData(chosenBot.botId);
      const response = await createOrUpdateBot({
        userId: user.id,
        botId: '',
        firstMessageValue: data.firstMessageValue,
        promptsSettingsFiltered: data.promptsSettings,
        toolKitSettings: data.toolKitSettings,
        endMessageValue: data.endMessageValue,
        errorMessageValue: data.errorMessageValue,
        chosenDocs: data.chosenDocs,
        botData: {
          bot_name: `Copy of - ${data.bot_name}`,
          bot_description: data.description,
          bot_audience: data.audience,
        },
      });
      const botId = response;
      const chosenBotTranslates = await getTranslates(chosenBot.botId);
      const translates = chosenBotTranslates.map((translate) => ({ ...translate, bot_id: botId }));
      if (translates.length > 0) await createTranslates(translates);
    });
    await getAllBotsUser();
    setSelectedOption(options[0]);
    setMarkedBots({ type: 'markAllBots', payload: { val: [] } });
    setSearchQuery('');
  };

  return (
    <>
      {isOpenBotCreator ? (
        <BotCreator
          getAllBotsUser={getAllBotsUser}
          botId={markedBots[0]?.botId}
          setIsOpenBotCreator={setIsOpenBotCreator}
          setSearchQuery={setSearchQuery}
          prompts={{
            analysisPrompt: analysisPrompt,
            gradePrompt: gradePrompt,
            characterPrompt: characterPrompt,
            relevantPrompt: relevantPrompt,
            hackPrompt: hackPrompt,
          }}
        />
      ) : (
        <>
          <div className="search-container">
            <input
              className="search-input"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <div className="custom-select-container">
              <div className="custom-select-selected" onClick={toggleDropdown}>
                <p className="sort-by-select">
                  Sort by: {selectedOption} <img src={ArrowDown} alt="" srcset="" />
                </p>
              </div>
              {isOpen && (
                <div className="custom-select-options">
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="custom-select-option"
                      onClick={() => handleOptionClick(option)}
                    >
                      <p>{option}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* DIFFERENT SORT - OPTIONAL */}

            {/* <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-name-label">Sort By</InputLabel>
              <Select
                className="select-box-tool-box"
                size="small"
                id="Model"
                value={selectedOption}
                label="Sort By"
                onChange={handleOptionClick}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          <div className="bot-list-page">
            <table>
              <thead className="bots-header">
                <tr style={{ backgroundColor: 'var(--chat-background-clr)' }}>
                  <th
                    style={{
                      textAlign: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginLeft: '8px', display: 'inline-block', verticalAlign: 'middle' }}>
                      <input type="checkbox" id={`checkboxId-all`} className="checkbox" />
                      <div
                        onClick={handleMarkAllBots}
                        style={{ display: 'inline-block', verticalAlign: 'middle' }}
                      >
                        {allBotsData &&
                        markedBots.length === botsDetails.length &&
                        botsDetails.length > 0 ? (
                          <CheckActiveIcon />
                        ) : (
                          <CheckIcon />
                        )}
                      </div>
                      <div
                        style={{ marginLeft: '10px', display: 'inline-block', verticalAlign: 'middle' }}
                      >
                        ({markedBots.length})
                      </div>
                    </div>
                  </th>
                  <th style={{ textAlign: 'left' }}>Name</th>
                  <th style={{ textAlign: 'left' }}>Owner</th>
                  <th style={{ textAlign: 'left' }}>Last Update</th>
                  <th style={{ textAlign: 'left' }}>Created At</th>
                  <th style={{ textAlign: 'left' }}>Active</th>
                  <th style={{ textAlign: 'left' }}>Status</th>
                  <th style={{ textAlign: 'left' }}>Usage</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {botsDetails.map((botDetails, index) => (
                  <SingleBotItem
                    markedBot={markedBots.some((bot) => bot.botId === botDetails.botId)}
                    onClick={() => onClickRow(botDetails)}
                    key={index}
                    botDetails={{ ...botDetails, botUrl: botUrl }}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <LowerNavbar
            setIsOpen={setIsOpen}
            getAllBotsUser={getAllBotsUser}
            setIsOpenBotCreator={setIsOpenBotCreator}
            openBotCreator={openBotCreator}
            getCharacterPrompt={getCharacterPrompt}
            getBotDefaultPrompts={getBotDefaultPrompts}
            setBotsDetails={setBotsDetails}
            setAllBotsData={setAllBotsData}
            duplicateBots={duplicateBots}
          />
        </>
      )}
    </>
  );
};

export default BotsList;
