import { clearUserJwtToken } from "../api/jwt";

export async function logout () {
  try {
    sessionStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
    const res = await clearUserJwtToken();
    if(res.status === 401) {
        throw new Error('Error clearing JWT token');
    }
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  } finally {
    if(window.location.pathname === '/') return
    window.location.replace('/');
  }
}
