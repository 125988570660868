import axios from '@/configs/axios.js';

export const  JWT_REFRESH_CHECK_INTERVAL = 5000
export const JWT_EXPIRATION = 300000

export const clearUserJwtToken = async () => {
    try {
        const res = await axios.post('/api/v1/iam/playground/clear-token');
        return res.status === 200
    } catch (error) {
        console.error(error)
        return false
    }
};

export const refreshUserJwtToken = async () => {
  try {
    const res = await axios.post('/api/v1/iam/playground/refresh-token')
    if(res.status !== 200) {
      throw new Error(`Error refreshing JWT token, got: ${res.status}`);
    }
    return true
  } catch (error) {
    return false
  }
};
