import './welcomePage.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '@/assets/login-logo.svg';
import { ReactComponent as CreditLogo } from '@/assets/credit-logo.svg';
import { isMobileAtom } from '@/atoms/general';
import { useAtom } from 'jotai';

export const WelcomePage = () => {
  const [isMobile] = useAtom(isMobileAtom);

  return (
    <div className="welcome-page">
      <div className="modal-full-screen">
        <div
          className="modal-container"
          style={isMobile ? { width: '90%', overflowY: 'visible' } : { width: '55%', overflowY: 'auto' }}
        >
          <div className="modal-upper">
            <Logo />
          </div>
          <h2>Welcome Back</h2>
          <div className="form-container">
            <Link to="/register">
              <button className="register-btn">Register</button>
            </Link>
            <Link className="link-margin-20" to="/login">
              <button className="login-btn">Login</button>
            </Link>
          </div>
        </div>
        <div className="bottom-credit" style={isMobile ? { left: '30%' } : { left: '45%' }}>
          <h4>Created By</h4>
          <CreditLogo />
        </div>
      </div>
    </div>
  );
};
