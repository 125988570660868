import { userAtom } from '@/atoms/general'
import { atom } from 'jotai'
import axios from '@/configs/axios.js';
import { getAuth } from 'firebase/auth'
import app from '@/configs/firebase.js';

const auth = getAuth(app)
export const finishedLoginAtom = atom(false)
export const makeSureLoginAtom = atom(null, async (get, set, navigate) => {

auth.onAuthStateChanged(async (user) => {
    if (user) {
      const tokenResult = await user.getIdTokenResult(true)
      console.log({tokenResult})
      if (tokenResult.claims) {
        // console.log(tokenResult.claims)


        // }
        var { data } = await axios.put('/api/user/login', {
          email: tokenResult.claims.email,
        })
        const { user } = data


      set(userAtom,{
        id:user.id.trim(), ///okta.accessToken.claims.uid,
        email:tokenResult.claims.email ,///okta.accessToken.claims.sub,
        userName:user.name ,//okta.accessToken.claims.sub,
        lang: 'english',
      })

      set(finishedLoginAtom,true)

      // console.log(!window.location.href.includes('register') && !window.location.href.includes('turbo') && !window.location.href.includes('data'))

      !window.location.href.includes('/') && !window.location.href.includes('register') && !window.location.href.includes('turbo') && !window.location.href.includes('data') && navigate.push('/playground')
      }
    } else if(!window.location.href.includes('register') && !window.location.href.includes('turbo')){
      navigate.push('/login')
    }
  })
})
