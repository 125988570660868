import axios from 'axios'
import { logout } from '../actions/auth.action';

function axiosInstance() {
    let instance = axios.create({});
    instance.updateAxiosToken = function (token) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };
    instance.interceptors.response.use((response) => response, async (error) => {
        const url = error.config.url
        console.log({ url })
        if(['/api/logs'].includes(url)) {
            console.log(`Skipping logout 401 for ${url}`)
            return
        }
        if (error.response.status === 401) {
            try {
                logout()
            } catch (error) {
                console.log({ error })
            }
        }
      })
    return instance;
}

export default axiosInstance()
